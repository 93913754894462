import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './NewsPage.css'; // Updated CSS filename

const NewsPage = () => {
  const [newsArticles, setNewsArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 12;

  useEffect(() => {
    const fetchNewsUpdates = async () => {
      try {
        const response = await axios.get(
          `https://newsapi.org/v2/everything?q="Hurricane Helene"&apiKey=9fa03410017649e683cc9d756e29f629`
        );

        console.log('News Feed Response:', response.data); // Log the response

        const heleneArticles = response.data.articles.filter(
          (article) =>
            article.title.includes('Helene') ||
            article.description.includes('Helene')
        );

        setNewsArticles(heleneArticles);
      } catch (error) {
        console.error('Error fetching news updates:', error);
      }
    };

    fetchNewsUpdates();
  }, []);

  // Calculate current articles to display
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = newsArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  // Handle pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(newsArticles.length / articlesPerPage);

  return (
    <div className="news-page-container">
      <header className="header">
        <h1>Hurricane Helene News Updates</h1>
        <p>Latest news articles specifically about Hurricane Helene.</p>
      </header>
      <div className="grid-container">
        {currentArticles.length > 0 ? (
          currentArticles.map((article) => (
            <div className="article-box" key={article.url}>
              <h3>
                <a href={article.url} target="_blank" rel="noopener noreferrer">
                  {article.title}
                </a>
              </h3>
              <p>{new Date(article.publishedAt).toLocaleString()}</p>
              <p>{article.description}</p>
            </div>
          ))
        ) : (
          <div>No news updates available for Hurricane Helene.</div>
        )}
      </div>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            className={currentPage === index + 1 ? 'active' : ''}
            onClick={() => paginate(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default NewsPage;
