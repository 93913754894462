import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Styles for Navbar

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar ">
      <div className="navbar-container max-width">
        <Link to="/" className="logo">
          <img src="https://louislawgroup.com/wp-content/uploads/2023/10/download-2.png" alt="Logo" />
        </Link>

        <ul className={`nav-menu ${isOpen ? 'open' : ''}`}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={toggleMenu}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/news" className="nav-links" onClick={toggleMenu}>
              News
            </Link>
          </li>
        </ul>

        <div className="nav-btn-container">
          {/* Replaced the button with a phone number link */}
          <a href="tel:+18336574812" className="nav-phone">
            +1 (833) 657-4812
          </a>
        </div>

        {/* Hamburger Icon */}
        <div className="hamburger" onClick={toggleMenu}>
          <div className={`line ${isOpen ? 'open' : ''}`}></div>
          <div className={`line ${isOpen ? 'open' : ''}`}></div>
          <div className={`line ${isOpen ? 'open' : ''}`}></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
