import React from 'react';
import Lottie from 'react-lottie';
import animationData from './home-damage.json'; // Update this with the path to your animation file
import './IntakeSub.css'; // You can add your CSS here for custom styles

const IntakeSub = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="intake-container" id="submit-claim">
      <div className="intake-inner">
        <div className="intake-text">
          <h2 className="intake-title">
            Submit Free Case Evaluation
          </h2>
          <p className="intake-description">
            We offer a swift and user-friendly tool to initiate your case, ensuring you get the representation you deserve without any unnecessary delays.
          </p>
          <div id="propertyDamage" className="tab-content">
            <div className="tab-text">
              <p>
                Our attorneys are experienced in property damage claims caused by accidents or natural disasters and will work tirelessly to get you proper compensation.
              </p>
              <a href="/property-damage-intake/" className="intake-link-button">
                Submit Free Case Evaluation  →
              </a>
            </div>
          </div>
        </div>
        <div className="intake-animation">
          <Lottie options={defaultOptions} height={300} width={300} />
        </div>
      </div>
    </div>
  );
};

export default IntakeSub;
