import React, { useState } from 'react';
import ReactMapGL, { Source, Layer, NavigationControl, GeolocateControl, ScaleControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MapPage.css'; // Responsive styles for the map
import SubIntake from '../components/IntakeSub'; // Navbar component
import InsuranceCompanies from '../components/InsuranceCompanies';
const MapPage = () => {
  const [viewport, setViewport] = useState({
    latitude: 25.7617, // Miami, FL (Default starting point)
    longitude: -80.1918,
    zoom: 4.3,
    width: '100%',
    height: '100vh', // Full-screen map
  });

  const OPENWEATHERMAP_API_KEY = 'a7e0a8f61500e61105c0c0c59e17b157'; // OpenWeatherMap API key
  const precipitationLayer = `https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=${OPENWEATHERMAP_API_KEY}`;

  return (
    <div>
      <div className="map-container">
        <ReactMapGL
          {...viewport}
          mapboxAccessToken="pk.eyJ1IjoieW1lc2FkZXYiLCJhIjoiY20weHJhcWY5MGVwZDJscTFiNGRlZmR2bSJ9.Z5Z9GpWQPgkBhees92Ud8A"
          mapStyle="mapbox://styles/mapbox/dark-v10" // Dark map style
          onViewportChange={(nextViewport) => setViewport(nextViewport)}
        >
          <Source
            id="precipitation-data"
            type="raster"
            tiles={[precipitationLayer]}
            tileSize={256}
          >
            <Layer
              id="precipitation-layer"
              type="raster"
              paint={{
                'raster-opacity': 1, // Adjust opacity if needed
              }}
            />
          </Source>

          {/* Map Controls */}
          <NavigationControl style={{ position: 'absolute', top: 10, right: 10 }} />
          <GeolocateControl positionOptions={{ enableHighAccuracy: true }} trackUserLocation={true} style={{ position: 'absolute', top: 50, right: 10 }} />
          <ScaleControl maxWidth={100} unit="imperial" style={{ position: 'absolute', bottom: 20, left: 10 }} />
        </ReactMapGL>
      </div>
      <div className="container max-width">
      <div className="section">
      <InsuranceCompanies />
      </div>
      <div className="section">
      <SubIntake />
      </div>
      </div>
    
    </div>
    
  );
};

export default MapPage;
