const insuranceCompanies = [
    
        { "name": "HERITAGE PROPERTY & CASUALTY INSURANCE COMPANY", "phone": "855-415-7120", "claimLink": "https://www.heritagepci.com/claims-center/" },
        { "name": "CITIZENS PROPERTY INSURANCE CORPORATION", "phone": "866-411-2742", "claimLink": "https://www.citizensfla.com/report-a-claim" },
        { "name": "UNIVERSAL PROPERTY & CASUALTY INSURANCE COMPANY", "phone": "800-425-9113", "claimLink": "https://universalproperty.com/claims-policyholder" },
        { "name": "AMERICAN INTEGRITY INSURANCE COMPANY OF FLORIDA", "phone": "866-968-8390", "claimLink": "https://www.aiicfl.com/" },
        { "name": "TOWER HILL INSURANCE EXCHANGE", "phone": "1-800-342-3407", "claimLink": "https://www.thig.com/companies/tower-hill-insurance-exchange/" },
        { "name": "STATE FARM", "phone": "1-800-782-8332", "claimLink": "https://www.statefarm.com/insurance/home-and-property/claims" },
        { "name": "ASI ASSURANCE CORPORATION", "phone": "1-202-413-1128", "claimLink": "https://asi-assurance.org/" },
        { "name": "LLOYD’S LONDON", "phone": "1-866-247-5677", "claimLink": "https://www.americanstrategic.com" },
        { "name": "HOMEOWNERS CHOICE PROPERTY & CASUALTY INSURANCE", "phone": "1-813-405-3600", "claimLink": "https://hcpci.com" },
        { "name": "PEOPLE’S TRUST INSURANCE COMPANY", "phone": "1-561-609-1000", "claimLink": "https://peoplestrustinsurance.com" },
        { "name": "UNITED PROPERTY & CASUALTY INSURANCE COMPANY", "phone": "1-561-609-1000", "claimLink": "https://peoplestrustinsurance.com/" },
        { "name": "SECURITY FIRST INSURANCE COMPANY", "phone": "1-877-333-9992", "claimLink": "https://www.securityfirstflorida.com" },
        { "name": "FLORIDA PENINSULA INSURANCE COMPANY", "phone": "1-877-229-2244", "claimLink": "https://www.floridapeninsula.com" },
        { "name": "FAMILY SECURITY INSURANCE COMPANY, INC.", "phone": "1-855-789-4976", "claimLink": "https://familysecurityplan.com" },
        { "name": "AMERICAN SECURITY INSURANCE COMPANY", "phone": "954-967-0000", "claimLink": "#" },
        { "name": "SAFEPOINT INSURANCE COMPANY", "phone": "1-877-858-7445", "claimLink": "https://safepointins.com/" },
        { "name": "FEDNAT INSURANCE COMPANY", "phone": "1-800-293-2532", "claimLink": "https://www.fednat.com" },
        { "name": "ST JOHNS INSURANCE COMPANY", "phone": "1-866-304-7779", "claimLink": "https://stjohnsinsurance.com" },
        { "name": "CASTLE KEY INDEMNITY COMPANY", "phone": "1-800-280-7250", "claimLink": "https://www.citainsurance.com/" },
        { "name": "FIRST PROTECTIVE INSURANCE COMPANY", "phone": "1-877-744-5224", "claimLink": "https://www.frontlineinsurance.com/" },
        { "name": "OLYMPUS INSURANCE COMPANY", "phone": "1-800-711-9386", "claimLink": "https://www.olympusinsurance.com" },
        { "name": "TYPTAP INSURANCE COMPANY", "phone": "1-407-838-3445", "claimLink": "https://typtap.com/" },
        { "name": "NATIONAL SPECIALTY INSURANCE COMPANY", "phone": "1-844-878-2567", "claimLink": "https://www.pontellinsurance.com/" },
        { "name": "UNIVERSAL INSURANCE COMPANY OF NORTH AMERICA", "phone": "1-866-999-0898", "claimLink": "https://www.uihna.com/en-US/" },
        { "name": "SOUTHERN FIDELITY INSURANCE COMPANY", "phone": "1-866-874-7342", "claimLink": "https://sfbli.com" },
        { "name": "SOUTHERN OAK INSURANCE COMPANY", "phone": "1-877-900-2280", "claimLink": "https://www.southernoak.com" },
        { "name": "EDISON INSURANCE COMPANY", "phone": "1-866-568-8922", "claimLink": "https://www.edisoninsurance.com/" },
        { "name": "ANCHOR PROPERTY & CASUALTY INSURANCE", "phone": "1-954-620-0019", "claimLink": "https://www.relyonanchor.com" },
        { "name": "AVATAR PROPERTY & CASUALTY INSURANCE COMPANY", "phone": "1-800-822-3054", "claimLink": "https://avatar-liquidation.com/" },
        { "name": "TRUCK INSURANCE EXCHANGE", "phone": "1-800-435-7764", "claimLink": "https://www.farmers.com/companies/truck-exchange-update/" },
        { "name": "SCOTTSDALE INSURANCE COMPANY", "phone": "800-423-7675", "claimLink": "https://www.ftpins.com/claims" },
        { "name": "GEOVARA SPECIALTY INSURANCE COMPANY", "phone": "800-631-6478", "claimLink": "https://www.mygeosource.com/Guest.aspx?DestPage=ReportClaim" },
        { "name": "USAA CASUALTY INSURANCE COMPANY", "phone": "(800) 531-8722", "claimLink": "https://www.usaa.com/inet/wc/insurance-file-claims-auto-property" },
        { "name": "LIBERTY MUTUAL FIRE INSURANCE COMPANY", "phone": "800-290-8206", "claimLink": "https://www.libertymutual.com/customer-support/claims-support" },
        { "name": "AMERICAN TRADITIONS INSURANCE COMPANY", "phone": "866-270-8430", "claimLink": "https://www.jergermga.com/claims-information/" },
        { "name": "CYPRESS PROPERTY & CASUALTY INSURANCE COMPANY", "phone": "123-456-7891", "claimLink": "Not found" },
        { "name": "PREPARED INSURANCE COMPANY", "phone": "(813) 286-3730", "claimLink": "https://jpperry.com/insurance-claims" },
        { "name": "CLEAR BLUE INSURANCE COMPANY", "phone": "855-542-0917", "claimLink": "https://www.colemanagencyfl.com/carriers-claims-numbers/" },
        { "name": "SLIDE INSURANCE COMPANY", "phone": "(866) 230-3758", "claimLink": "https://www.slideinsurance.com/claimscenter" },
        { "name": "QBE Specialty Insurance Company", "phone": "844-723-2524", "claimLink": "https://www.qbe.com/au/claims" },
        { "name": "FIRST COMMUNITY INSURANCE COMPANY", "phone": "(800) 331-2942", "claimLink": "https://firstcommunityins.com/insurance-claims" },
        { "name": "UNITED SERVICES AUTOMOBILE ASSOCIATION", "phone": "(800) 531-8722", "claimLink": "https://www.usaa.com/inet/wc/insurance-file-claims-auto-property" },
        { "name": "FOREMOST INSURANCE COMPANY GRAND RAPIDS, MICHIGAN", "phone": "800-527-3907", "claimLink": "https://www.foremost.com/claims/" },
        { "name": "AMERICAN COASTAL INSURANCE COMPANY", "phone": "321-725-1440", "claimLink": "https://www.coastalinsgroup.com/insurance-center/service/insurance-claim-contacts/" },
        { "name": "MONARCH NATIONAL INSURANCE COMPANY", "phone": "800-293-2532", "claimLink": "https://monarchnational.com/claims/" },
        { "name": "LEXINGTON INSURANCE COMPANY", "phone": "(800) 931-9546", "claimLink": "https://www.lexingtoninsurance.com/home/claims" },
        { "name": "FEDERAL INSURANCE COMPANY", "phone": "301-468-9600", "claimLink": "https://www.fednat.com/claims/report-claim/" },
        { "name": "CENTAURI SPECIALTY INSURANCE COMPANY", "phone": "866-789-4228", "claimLink": "https://centauriinsurance.com/claims-draft/" },
        { "name": "KIN INTERINSURANCE NETWORK", "phone": "866-204-2219", "claimLink": "https://www.kin.com/claims/" },
        { "name": "AMERICAN STRATEGIC INSURANCE CORP", "phone": "866-274-5677", "claimLink": "https://www.americanstrategic.com/claims-center/report-a-claim" },
        { "name": "NATIONWIDE PROPERTY AND CASUALTY INSURANCE COMPANY", "phone": "800-421-3535", "claimLink": "https://www.nationwide.com/personal/insurance/claims/faq/" },
        { "name": "TRANSVERSE SPECIALTY INSURANCE COMPANY", "phone": "609-250-7841", "claimLink": "Not found" },
        { "name": "WILSHIRE INSURANCE COMPANY", "phone": "(800) 780-8423", "claimLink": "https://wilshireclaims.com/" },
        { "name": "FLORIDA SPECIALTY INSURANCE COMPANY", "phone": "800-988-1450", "claimLink": "https://jpperry.com/insurance-claims" },
        { "name": "STATE NATIONAL INSURANCE COMPANY INC.", "phone": "800-877-4567", "claimLink": "https://www.cornerstone-underwriters.com/Claims" },
        { "name": "PROGRESSIVE PROPERTY INSURANCE COMPANY", "phone": "800-776-4737", "claimLink": "https://www.progressive.com/claims/property-process/" },
        { "name": "OMEGA INSURANCE COMPANY", "phone": "813-341-1530", "claimLink": "https://www.trustomega.com/service-center/claims-payments" },
        { "name": "VYRD INSURANCE COMPANY", "phone": "844-217-6993", "claimLink": "https://www.vyrd.co/claims/" },
        { "name": "VAULT RECIPROCAL EXCHANGE", "phone": "844-368-2858", "claimLink": "https://www.vaultinsurance.com/submit-a-claim" }
    ]
  ;
  
  export default insuranceCompanies;
  