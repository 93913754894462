import React, { useState } from 'react';
import './InsuranceCompanies.css';
import insuranceCompanies from './InsuranceCompaniesData'; // Import the insurance companies data

const InsuranceCompanies = () => {
  const [visibleCompanies, setVisibleCompanies] = useState(12);

  // Helper function to slugify company names
  const slugify = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
  };

  const showMoreCompanies = () => {
    setVisibleCompanies(prevVisible => prevVisible + 12);
  };

  return (
    <div className="insurance-companies-container">
      <div className='insurance-text-cont'>
        <h2>Start Your Insurance Claim</h2>
        <p>
          At Louis Law Group, we understand the challenges of dealing with hurricane damage, especially after Hurricane Helene. Our experienced insurance litigation attorneys are here to help you get the compensation you deserve. Insurance companies often delay or underpay claims, but we’re dedicated to holding them accountable.
        </p>
      </div>
      <div className="company-grid">
        {insuranceCompanies.slice(0, visibleCompanies).map((company, index) => (
          <div className="company-box" key={index}>
            <h3>{company.name}</h3>
            <p> <a href={`tel:${company.phone}`} className="phone-link">{company.phone}</a></p>
            {company.claimLink && (
              <a 
                href={`/insurance-guide/${slugify(company.name)}`} 
                className="claim-link"
              >
                How to File a Claim
              </a>
            )}
          </div>
        ))}
      </div>
      {visibleCompanies < insuranceCompanies.length && (
        <button onClick={showMoreCompanies} className="show-more-btn">
          Show More
        </button>
      )}
    </div>
  );
};

export default InsuranceCompanies;
