import React from 'react';
import { useParams } from 'react-router-dom';
import SubmitIntake from './IntakeSub'; // Import the Submit Intake component
import insuranceCompanies from './InsuranceCompaniesData'; // Import the correct data file
import './InsuranceGuide.css';

const InsuranceGuide = () => {
  const { companyName } = useParams(); // Get the company name from the URL

  // Helper function to slugify company names consistently
  const slugify = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
  };

  // Find the matching company from the imported data
  const company = insuranceCompanies.find(c => slugify(c.name) === companyName);

  if (!company) {
    return <div>Company not found</div>; // Handle case if no company matches the URL
  }

  return (
    <div className="container max-width">
    <div className="insurance-guide">
    <h2>How to File a Claim with {company.name}</h2>
    
    <p>If you are a policyholder with {company.name}, follow these essential steps to ensure your claim is processed smoothly and efficiently:</p>
  
    <ol>
      <li><strong>Document the Damage:</strong> Begin by thoroughly documenting any damage to your property. Take clear photographs or videos of all affected areas, including both interior and exterior damage. Detailed records will support your claim and help expedite the process.</li>
      <li><strong>Review Your Policy:</strong> Carefully read through your insurance policy to understand your coverage. Pay attention to any exclusions or limitations that may apply, as well as the process for filing a claim.</li>
      <li><strong>Initiate Your Claim:</strong> Contact {company.name} directly to begin the claims process. Use the phone number provided below to speak with a representative, or visit their website using the claim link for online submission.</li>
    </ol>
  
    <p><strong>Phone:</strong> <a href={`tel:${company.phone}`}>{company.phone}</a></p>
    
    {company.claimLink && (
      <p><strong>Online Claim Submission:</strong> <a href={company.claimLink} target="_blank" rel="noopener noreferrer">File a Claim</a></p>
    )}
  
    <p>At Louis Law Group, we understand how stressful and complex the claims process can be, especially in the aftermath of a disaster. If you're facing delays, low settlement offers, or denials from {company.name}, our experienced insurance attorneys are here to help. We’ll handle the legal complexities, advocate on your behalf, and ensure you receive the compensation you're entitled to.</p>
  
    <SubmitIntake /> {/* Display the intake form */}
  </div>
  </div>
  
  );
};

export default InsuranceGuide;
