import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MapPage from './pages/MapPage'; // Ensure MapPage.js exists in the pages folder
import NewsPage from './pages/NewsPage'; // Ensure NewsPage.js exists in the pages folder
import Navbar from './components/Navbar'; // Navbar component
import Footer from './components/Footer'; // Footer component
import InsuranceCompanies from './components/InsuranceCompanies'; // Insurance Companies component
import InsuranceGuide from './components/InsuranceGuide'; // Dynamic Insurance Guide for each company
import './App.css'; // Your global styling

function App() {
  return (
    <Router>
      <div className="app-container">
        {/* Add Navbar to all pages */}
        <Navbar />
        
        {/* Main Routes */}
        <div className="content-container">
          <Routes>
            {/* Map Page as Home */}
            <Route path="/" element={<MapPage />} />

            {/* News Page */}
            <Route path="/news" element={<NewsPage />} />

            {/* Insurance Companies Page */}
            <Route path="/insurance-companies" element={<InsuranceCompanies />} />

            {/* Dynamic Insurance Guide Page for each company */}
            <Route path="/insurance-guide/:companyName" element={<InsuranceGuide />} />
          </Routes>
        </div>

        {/* Add Footer to all pages */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
